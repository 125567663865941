@import 'bootstrap/dist/css/bootstrap.min.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .sidebar-btn-wrapper {
    display: none;
  }
  
  main {
    padding: 10px !important;
  }
}

.activeClicked {
  background-color: #4b4b4b;
  text-decoration: none;
}

.activeClicked:hover {
  text-decoration: none;
}

.pro-sidebar-width {
  transition: width 0.3s ease !important;
}

.modern-sidebar {
  transition: width 0.3s ease !important;
  box-shadow: 2px 0 8px rgba(0,0,0,0.1) !important;
}

.modern-sidebar .pro-sidebar-inner {
  background: linear-gradient(180deg, #1a1a1a 0%, #2d2d2d 100%) !important;
}

.menu-item {
  text-decoration: none !important;
  color: #f8f9fa !important;
  transition: all 0.2s ease !important;
  margin: 4px 8px !important;
  border-radius: 6px !important;
}

.menu-item:hover {
  background-color: rgba(255,255,255,0.1) !important;
  transform: translateX(4px);
}

.menu-item.active {
  background-color: #007bff !important;
  color: white !important;
}

.menu-item.logout {
  margin-top: auto !important;
  color: #dc3545 !important;
}

.menu-item.logout:hover {
  background-color: rgba(220,53,69,0.1) !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 12px 16px !important;
  border-radius: 6px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: white !important;
}

.pro-sidebar-header {
  border-bottom: 1px solid rgba(255,255,255,0.1) !important;
}

.pro-sidebar .pro-menu {
  padding: 16px 8px !important;
}

.pro-icon {
  font-size: 18px !important;
  min-width: 24px !important;
}

.sidebar-toggle {
  transition: background-color 0.2s ease, transform 0.2s ease !important;
}

.sidebar-toggle:hover {
  background-color: rgba(255,255,255,0.1) !important;
  transform: scale(1.1) !important;
}

.ps-sidebar-root {
  border: none !important;
  background: #1a1a1a !important;
}

.ps-menu-button {
  padding: 12px 24px !important;
  margin: 4px 8px !important;
  border-radius: 6px !important;
  color: #fff !important;
  transition: all 0.2s ease !important;
}

.ps-menu-button:hover {
  background-color: #2b2b2b !important;
  transform: translateX(4px);
}

.ps-menu-button.active {
  background-color: #2b2b2b !important;
}

.ps-menu-icon {
  font-size: 18px !important;
  width: 24px !important;
  min-width: 24px !important;
  margin-right: 16px !important;
}

.ps-sidebar-container {
  box-shadow: 2px 0 8px rgba(0,0,0,0.1) !important;
}
